import React from 'react';
import styled, { keyframes } from 'styled-components';
import colours from '../global/colours';
import { fontSizes, fontLineHeight } from '../global/fonts';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

const fadein = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const AccordionWrapper = styled.div``;
const SkinnyAccordion = styled(Accordion)`
    width: 100%;
`;
const SkinnyAccordionItemButton = styled(AccordionItemButton)`
    border-bottom: solid 1px ${colours.lightGrey};
    cursor: pointer;
    ${fontSizes.accordionTitle.size};
    ${fontLineHeight.accordionTitle.lineHeight};
    transition: all 500ms ease;
    color: ${colours.darkerGrey};
    padding: 1em 0;
    position: relative;
    .icon {
        transform: rotate(0deg);
        position: absolute;
        right: 10px;
    }
    &:hover {
        color: ${colours.lightBlack};
    }
    &[aria-expanded='true'] {
        .icon {
            transform: rotate(180deg);
        }
    }
    &:focus {
        outline: 0;
    }
`;
const SkinnyAccordionItemPanel = styled(AccordionItemPanel)`
    animation: ${fadein} 0.35s ease-in;
    color: ${colours.darkerGrey};
    padding: 1rem 0;
    border-bottom: solid 1px ${colours.lightGrey};
`;

/** Using index as key as the content is static */
const SkinnyAccordionComponent = props => (
    <AccordionWrapper>
        {props.heading && <h2>{props.heading}</h2>}
        <SkinnyAccordion>
            {props.content.map((item, index) => (
                <AccordionItem key={index}>
                    <AccordionItemHeading>
                        <SkinnyAccordionItemButton>
                            <img className="icon" alt="Icon" src="https://skinny.co.nz/app/images/arrow--down.svg" />
                            {item.title}
                        </SkinnyAccordionItemButton>
                    </AccordionItemHeading>
                    <SkinnyAccordionItemPanel>
                        <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                    </SkinnyAccordionItemPanel>
                </AccordionItem>
            ))}
        </SkinnyAccordion>
    </AccordionWrapper>
);

export default SkinnyAccordionComponent;
