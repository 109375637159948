import React from 'react';
import styled from 'styled-components';
import colours from '../global/colours';
import { articleTypes } from '../global/constants';

const FeaturedArticlesWrapper = styled.div``;
const ArticleLink = styled.a`
    color: ${colours.grey};
    padding-bottom: 10px;
    display: block;
    border: 0;
    &:hover,
    &:visited {
        font-weight: bold;
        color: ${colours.grey};
    }
`;

const SkinnyPhpKbFeaturedArticlesComponent = ({ baseURL, content }) => (
    <FeaturedArticlesWrapper>
        {content &&
            content.map((item) => {
                return item.type
                    ? item.type === articleTypes.PUBLIC && (
                          <ArticleLink
                              key={item.id}
                              href={`/${baseURL}#/article/${item.id}/${item.title.replace(/\s/g, '-')}`}
                          >
                              <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                          </ArticleLink>
                      )
                    : item.categories &&
                          item.categories.result &&
                          item.categories.result.length > 0 &&
                          item.categories.result[0].status === articleTypes.PUBLIC && (
                              <ArticleLink
                                  key={item.id}
                                  href={`/${baseURL}#/article/${item.id}/${item.title.replace(/\s/g, '-')}`}
                              >
                                  <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
                              </ArticleLink>
                          );
            })}
    </FeaturedArticlesWrapper>
);

export default SkinnyPhpKbFeaturedArticlesComponent;
