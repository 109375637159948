import React from 'react';
import styled from 'styled-components';
import { SkinnyCategoryCardComponent, SkinnyHomeCardComponent } from './SkinnyCardComponent';
import SkinnyPhpKbSearchComponent from './SkinnyPhpKbSearchComponent';
import { Heading1 } from '../global/Headings';
import colours from '../global/colours';
import { patternBg } from '../global/images';

const SkinnyHomeComponentWrapper = styled.div`
    padding-bottom: 200px;
    background: linear-gradient(180deg, ${colours.white} 60%, ${colours.orange} 40%);
    background: url('${patternBg}') no-repeat bottom left, linear-gradient(180deg, ${colours.white} 60%, ${colours.orange} 40%);
`;

const SkinnyCardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
`;

const SkinnyPhpKbHomeComponent = (props) => {
    const view = props.match.params.view;

    return (
        <SkinnyHomeComponentWrapper>
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <Heading1>{props.heading}</Heading1>
                            <p>{props.description}</p>

                            <SkinnyPhpKbSearchComponent history={props.history} />
                            {view === 'categoryview' ? (
                                <SkinnyCardWrapper>
                                    {props.category.map((item) => (
                                        <SkinnyCategoryCardComponent
                                            key={item.id}
                                            cid={item.id}
                                            title={item.title}
                                            desc={item.desc}
                                            image={item.image}
                                            desktopWidth={props.desktopWidth || '30.5%'}
                                            mobileWidth={props.mobileWidth || '100%'}
                                            baseURL={props.baseURL}
                                        />
                                    ))}
                                </SkinnyCardWrapper>
                            ) : (
                                <SkinnyCardWrapper>
                                    {props.mainLinks.map((item) => (
                                        <SkinnyHomeCardComponent
                                            key={item.id}
                                            title={item.title}
                                            desc={item.desc}
                                            image={item.image}
                                            desktopWidth={props.desktopWidth || '48%'}
                                            mobileWidth={props.mobileWidth || '100%'}
                                            baseURL={props.baseURL}
                                            linkName={item.linkname}
                                            linkValue={item.linkvalue}
                                        />
                                    ))}
                                </SkinnyCardWrapper>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SkinnyHomeComponentWrapper>
    );
};

export default SkinnyPhpKbHomeComponent;
