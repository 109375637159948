import { apiKey } from '../components/global/apiConstants';

const baseUrl = `https://support.skinny.co.nz/api.php?key=${apiKey}`;

async function fetchSubCategories(cid) {
    let response = await fetch(`${baseUrl}&call=categories&id=${cid}&subcats=yes`);
    const res = await response.json();
    return res;
}

async function fetchArticles(cid) {
    let response = await fetch(
        `${baseUrl}&call=articles&cid=${cid}&fields=title,id,type,content&mode=text&charCount=200`,
    );
    const res = await response.json();
    return res;
}

async function fetchPopularArticles() {
    let response = await fetch(
        `${baseUrl}&call=articles&fields=title,id,type&mode=text&charCount=200&method=popular&limit=5`,
    );
    const res = await response.json();
    return res;
}

async function fetchFeaturedArticles() {
    let response = await fetch(
        `${baseUrl}&call=articles&fields=title,id,type&mode=text&charCount=200&method=featured&limit=5`,
    );
    const res = await response.json();
    return res;
}

async function fetchCurrentArticle(aid) {
    let response = await fetch(`${baseUrl}&id=${aid}&call=articles`);
    const res = await response.json();
    return res;
}

async function searchArticles(searchText, limit) {
    const tmpSearchText = searchText.replace(/\s/g, '+');
    let response = await fetch(
        `${baseUrl}&call=search&for=articles&k=${tmpSearchText}&mode=text&in=title&fields=id,title,author,summary,categories&limit=${limit}`,
    );
    const res = await response.json();
    return res;
}

export {
    fetchSubCategories,
    fetchArticles,
    fetchPopularArticles,
    fetchFeaturedArticles,
    fetchCurrentArticle,
    searchArticles,
};
