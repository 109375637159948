import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import Autosuggest from 'react-autosuggest';
import { DebounceInput } from 'react-debounce-input';
import { searchArticles } from '../../api/PhpKbApi';
import colours from '../global/colours';
import { SearchIcon } from '../global/Icon';
import { articleTypes } from '../global/constants';

const AutosuggestClasses = {
    container: 'react-autosuggest__container',
    containerOpen: 'react-autosuggest__container--open',
    input: 'react-autosuggest__input',
    inputOpen: 'react-autosuggest__input--open',
    inputFocused: 'react-autosuggest__input--focused',
    suggestionsContainer: 'react-autosuggest__suggestions-container',
    suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    suggestionsList: 'react-autosuggest__suggestions-list',
    suggestion: 'react-autosuggest__suggestion',
    suggestionFirst: 'react-autosuggest__suggestion--first',
    suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    sectionContainer: 'react-autosuggest__section-container',
    sectionContainerFirst: 'react-autosuggest__section-container--first',
    sectionTitle: 'react-autosuggest__section-title',
};

const SearchBoxWrapper = styled.div`
    display: flex;
    margin-bottom: 50px;
    width: 100%;
    margin: 40px 0 0 0;
    position: relative;
    .fake_placeholder {
        top: 16px;
        left: 24px;
        position: absolute;
        display: ${(prop) => (prop.searchValue ? 'none' : 'block')};
    }
    .${AutosuggestClasses.container} {
        width: 100%;
    }
    .${AutosuggestClasses.input} {
        width: 100%;
        height: 48px;
        border-radius: ${(prop) => (prop.styleValue ? '20px 0 0 0' : '20px 0 0 20px')};
        border: solid 1px ${colours.lightGrey};
        font-size: 16px;
        color: ${colours.grey};
        line-height: 1.25;
        text-align: left;
        padding: 0 24px;
        outline: none;
        &::-ms-clear {
            display: none;
        }
        &::placeholder {
            color: ${colours.grey};
        }
    }
    .${AutosuggestClasses.suggestionsContainerOpen} {
        position: absolute;
        top: 48px;
        padding: 20px 0;
        border: solid 1px ${colours.lightGrey};
        background: white;
        border-radius: 0 0 20px 20px;
        width: 100%;
        z-index: 10;
    }

    .${AutosuggestClasses.suggestionsList} {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .${AutosuggestClasses.suggestion} {
        padding: 5px 20px;
        margin: 0;
        cursor: pointer;
        &:hover {
            background: ${colours.lightGrey};
        }
        .matched {
            background: ${colours.lightGrey};
        }
    }
`;

const SearchButton = styled.div`
    border-radius: ${(prop) => (prop.styleValue ? '0 20px 0 0' : '0 20px 20px 0')};
    padding: 5px 20px;
    background: ${colours.orange};
    cursor: pointer;
    width: 78px;
`;

const escapeEl = document.createElement('textarea');

const SkinnyPhpKbSearchComponent = ({ history, defaultValue }) => {
    const [searchValue, setSearchValue] = useState(defaultValue || '');
    const [articles, setArticles] = useState([]);
    const [styleValue, setStyleValue] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const searchInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const displayArticle = (e) => {
        if (selectedItem) {
            history.push({
                pathname: `/article/${selectedItem.id}/${selectedItem.title.toLowerCase().replace(/\s/g, '-')}`,
            });
        }
    };

    const searchResults = (e) => {
        if (searchValue) {
            history.push({
                pathname: `/search/${searchValue}`,
            });
        }
    };

    const pressKeyDown = (e) => {
        if (e.keyCode == 13) {
            if (searchValue) {
                setStyleValue(false);
                history.push({
                    pathname: `/search/${searchValue}`,
                });
            }
        }
    };

    const onSuggestionSelected = (_, { suggestion }) => {
        setSearchValue(unescapeHTML(suggestion.title));
        setSelectedItem(suggestion);
        setStyleValue(false);
        setTrigger(true);
    };

    const unescapeHTML = function (html) {
        escapeEl.innerHTML = html;
        return escapeEl.textContent;
    };

    const renderSuggestion = (suggestion) => {
        if (suggestion.length === 0) {
            return <div />;
        }

        return <div dangerouslySetInnerHTML={{ __html: suggestion.title }}></div>;
    };

    const emptyArticles = () => {
        setArticles([]);
        setStyleValue(false);
    };

    const getArticles = (searchValue) => {
        const tmpSearchValue = searchValue.value;
        setArticles([{ id: '0', title: 'Loading...' }]);
        setStyleValue(true);

        return searchArticles(tmpSearchValue, 20)
            .then((data) => {
                const tmpData = data && data.result ? data.result : [];
                const setNewData = tmpData.filter(function (item) {
                    if (item.categories && item.categories.result && item.categories.result.length > 0) {
                        if (item.categories.result[0].status === articleTypes.PUBLIC) {
                            return item;
                        }
                    }
                });

                setArticles(setNewData);
                setStyleValue(true);
            })
            .catch((error) => {
                emptyArticles();
            });
    };

    debounce(getArticles, 500);

    const inputProps = {
        placeholder: 'Enter your search',
        value: searchValue,
        onChange: searchInputChange,
        onKeyDown: pressKeyDown,
    };
    const renderSearchInput = (inputProps) => (
        <DebounceInput minLength={3} debounceTimeout={500} autoFocus {...inputProps} />
    );

    useEffect(() => {
        if (trigger) {
            displayArticle();
        }
    }, [trigger]);
    return (
        <SearchBoxWrapper styleValue={styleValue} searchValue={searchValue}>
            <Autosuggest
                suggestions={[...articles]}
                onSuggestionsFetchRequested={getArticles}
                onSuggestionsClearRequested={emptyArticles}
                getSuggestionValue={(suggestion) => suggestion.title}
                renderSuggestion={(suggestion) => renderSuggestion(suggestion)}
                inputProps={inputProps}
                renderInputComponent={renderSearchInput}
                onSuggestionSelected={onSuggestionSelected}
                focusInputOnSuggestionClick={false}
                theme={AutosuggestClasses}
            />
            <SearchButton title="autocompleteSubmitBtn" onClick={searchResults} styleValue={styleValue}>
                <SearchIcon height="32" width="34" color={colours.white} />
            </SearchButton>
        </SearchBoxWrapper>
    );
};

export default SkinnyPhpKbSearchComponent;
