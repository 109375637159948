import { inject } from 'react-web-component-injector';
import * as serviceWorker from './serviceWorker';

import HomePromoComponent from './components/HomePromoComponent/HomePromoComponent';
import SkinnyAccordionComponent from './components/SkinnyAccordionComponent/SkinnyAccordionComponent';
import SkinnyTableComponent from './components/SkinnyTableComponent/SkinnyTableComponent';
import { SkinnyPhpKbApp } from './components/SkinnyPhpKbComponent/SkinnyPhpKbApp';

inject({
    'home-promo': HomePromoComponent,
    'skinny-accordion': SkinnyAccordionComponent,
    'skinny-table': SkinnyTableComponent,
    'skinny-phpkb': SkinnyPhpKbApp,
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
