import React from 'react';
import styled from 'styled-components';
import colours from '../global/colours';
import { fontSizes, fontLineHeight } from '../global/fonts';
import Media from '../global/media';

const HomePromoItemWrapper = styled.a`
    display: flex;
    justify-content: center;
    width: 100%;
    background: ${prop => prop.bgColour || colours.darkGrey};
    padding: 50px 20px;
    color: ${colours.white};
    border-bottom: 0;
    transition: 0.25s ease-in-out;
    flex-wrap: wrap;
    ${fontSizes.homePagePromoItem};
    ${fontLineHeight.homePagePromoItem};
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    &:visited {
        color: ${colours.white};
    }
    &:hover {
        background-size: 30px 30px;
        color: ${colours.white};
        background-position: 0 0, 15px 15px;
        background-image: radial-gradient(${colours.grey} 15%, transparent 15%),
            radial-gradient(${colours.grey} 15%, transparent 15%);
    }
    &:link {
        -webkit-tap-highlight-color: ${colours.white};
    }

    ${Media.sm} {
        justify-content: ${prop => prop.contentAlign || 'flex-start'};
        padding: 50px 100px;
    }
`;
const ContentContainer = styled.div`
    max-width: 200px;
    text-align: center;
`;
const Image = styled.img`
    height: 100px;
`;

const HomePromoItem = props => (
    <HomePromoItemWrapper href={props.link} contentAlign={props.contentalign} bgColour={props.bgColour}>
        <ContentContainer>
            <Image src={props.svg} alt={props.content} />
            <div>{props.content}</div>
        </ContentContainer>
    </HomePromoItemWrapper>
);

export default HomePromoItem;
