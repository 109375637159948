import React from 'react';

const SearchIcon = ({ width, height, color }) => (
    <svg width={width} height={height} viewBox="0 0 512 512" fill={color}>
        <title>Search Icon</title>
        <path d="m445 426l-122-122c21-25 34-57 34-93 0-80-65-144-145-144-80 0-145 64-145 144 0 81 65 145 145 145 35 0 67-12 92-33l122 122z m-351-214c0-65 53-118 118-118 64 0 117 53 117 118 0 64-53 117-117 117-65 0-118-53-118-117z" />
    </svg>
);

const ChatIcon = ({ width, height }) => (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119 119">
        <g id="Layer_2" data-name="Layer 2">
            <circle className="cls-1" cx="59.5" cy="59.5" r="42.5" fill="#ff6b00" />
        </g>
        <g id="Layer_1" data-name="Layer 1">
            <path
                className="cls-2"
                fill="#fff"
                fill-rule="evenodd"
                d="M68.5,62V44.9A1.9,1.9,0,0,0,66.6,43H41.9A1.9,1.9,0,0,0,40,44.9V71.5l7.6-7.6h19A1.91,1.91,0,0,0,68.5,62Zm7.6-11.4H72.3V67.7H47.6v3.8a1.91,1.91,0,0,0,1.9,1.9H70.4L78,81V52.5A1.91,1.91,0,0,0,76.1,50.6Z"
            />
        </g>
    </svg>
);

const EmailIcon = ({ width, height }) => (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119 119">
        <g id="Layer_2" data-name="Layer 2">
            <circle className="cls-1" cx="59.5" cy="59.5" r="42.5" fill="#ff6b00" />
        </g>
        <g id="Layer_1" data-name="Layer 1">
            <path
                className="cls-2"
                fill="#fff"
                fill-rule="evenodd"
                d="M42.18,47.73a4.64,4.64,0,0,1,2.5-.73H74.32a4.7,4.7,0,0,1,2.61.79L61,60a2.49,2.49,0,0,1-2.9,0Zm-2,2.63L55.71,62.29a6.38,6.38,0,0,0,7.64,0L78.84,50.48A4.42,4.42,0,0,1,79,51.69V72.31A4.69,4.69,0,0,1,74.32,77H44.68A4.68,4.68,0,0,1,40,72.31V51.69A4.86,4.86,0,0,1,40.19,50.36Z"
            />
        </g>
    </svg>
);
export { SearchIcon, ChatIcon, EmailIcon };
