/* Grid Breakpoints as per bootstrap 3 */

export const breakpoints = {
    sm: 600 /* Small Device  */,
    md: 840 /* Medium Device */,
    lg: 1020 /* Large Device */,
};

const media = Object.keys(breakpoints).reduce(
    (sum, breakpoint) =>
        Object.assign(sum, {
            [breakpoint]: `@media screen and (min-width: ${breakpoints[breakpoint]}px)`,
        }),
    {},
);

export default media;
