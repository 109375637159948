import React from 'react';
import styled from 'styled-components';
import media from '../global/media';
import colours from '../global/colours';

const TableWrapper = styled.div``;

const Table = styled.table`
    width: 100%;
    table-layout: fixed;
`;

const Body = styled.tbody``;

const Row = styled.tr`
    padding: 0;
    ${media.sm} {
        padding-top: 1em;
        padding-bottom: 1em;
    }
`;

const Header = styled.thead`
    display: none;
    padding: 0;
    ${Row} {
        background: ${colours.lightGrey};
    }
    ${media.sm} {
        display: contents;
    }
`;

const HeaderCell = styled.th`
    text-align: left;
    ${media.sm} {
        padding: 0.75em 0.75em;
    }
`;

const Cell = styled.td`
    width: 100%;
    display: block;
    padding: 0.75em 0.75em 0.75em 50%;
    position: relative;
    border-bottom: solid 1px ${colours.lightGrey};
    &:before {
        content: attr(data-header);
        background: ${colours.lightGrey};
        width: 40%;
        position: absolute;
        font-weight: bold;
        left: 0;
        top: 0;
        padding: 0.75em 0.75em 0.75em;
    }
    &:first-child {
        font-weight: normal;
    }
    ${media.sm} {
        padding: 0.75em 0.75em;
        display: table-cell;
        &::before {
            content: '';
            display: none;
        }
    }
`;

/** Using index as key as the content is static */
const SkinnyTableComponent = props => (
    <TableWrapper>
        <Table>
            <Header>
                <Row>
                    {props.headers.map((item, index) => (
                        <HeaderCell key={index} data-header={item}>
                            {item}
                        </HeaderCell>
                    ))}
                </Row>
            </Header>

            <Body>
                {props.content.body.map((row, index) => (
                    <Row key={index}>
                        {row.map((item, index1) => (
                            <Cell key={index1} data-header={props.headers[index]}>
                                {item}
                            </Cell>
                        ))}
                    </Row>
                ))}
            </Body>
        </Table>
    </TableWrapper>
);

export default SkinnyTableComponent;
