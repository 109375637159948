import React from 'react';
import styled from 'styled-components';
import colours from '../global/colours';
import media from '../global/media';

const SkinnyCardWrap = styled.div`
    width: ${(prop) => prop.mobileWidth || '100%'};
    transition: all 0.5s ease-in-out;
    margin: 0 0 40px 0;
    &:hover {
        transform: scale(1.05);
    }
    .button {
        visibility: visible;
    }
    ${media.sm} {
        .button {
            visibility: hidden;
        }
        width: ${(prop) => prop.desktopWidth || '100%'};
        margin-right: 4%;
        &:nth-child(3),
        &:nth-child(6) {
            margin-right: 0;
        }
        &:hover {
            .button {
                visibility: visible;
            }
        }
    }
`;

const SkinnyHomeCardWrap = styled.div`
    width: ${(prop) => prop.mobileWidth || '100%'};
    transition: all 0.5s ease-in-out;
    margin: 0 0 40px 0;
    &:hover {
        transform: scale(1.05);
    }
    .button {
        visibility: visible;
    }
    ${media.sm} {
        .button {
            visibility: hidden;
        }
        width: ${(prop) => prop.desktopWidth || '100%'};
        margin-right: 4%;
        &:nth-child(2),
        &:nth-child(4) {
            margin-right: 0;
        }
        &:hover {
            .button {
                visibility: visible;
            }
        }
    }
`;

const SkinnyCard = styled.a`
    width: 100%;
    display: block;
    padding: 20px;
    cursor: pointer;
    border-bottom: 0;
    border-radius: 5px;
    box-shadow: 0 0 5px 1px ${colours.lightGrey};
    background: ${colours.white};
    &:hover {
        background: ${colours.lightGrey};
    }
`;

const CardImage = styled.img`
    width: 40px;
    margin-bottom: 20px;
`;

const CardTitle = styled.h3`
    position: relative;
    margin-bottom: 30px;
    color: ${colours.orange};
    &:after {
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 20px;
        height: 3px;
        background: ${colours.orange};
    }
`;

const CardDesc = styled.p`
    color: ${colours.darkerGrey};
`;

const SkinnyHomeCardComponent = ({
    baseURL,
    desktopWidth,
    mobileWidth,
    image,
    title,
    desc,
    internalLink,
    linkValue,
    linkName,
}) => (
    <SkinnyHomeCardWrap desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
        <SkinnyCard href={linkValue}>
            <CardImage src={image} />
            <CardTitle>{title}</CardTitle>
            <CardDesc>{desc}</CardDesc>
            <div class="button">{linkName}</div>
        </SkinnyCard>
    </SkinnyHomeCardWrap>
);

const SkinnyCategoryCardComponent = ({ baseURL, cid, desktopWidth, mobileWidth, image, title, desc }) => (
    <SkinnyCardWrap desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
        <SkinnyCard href={`/${baseURL ? baseURL : ''}#/category/${cid}/0/${title.replace(/\s/g, '-')}`}>
            <CardImage src={image} />
            <CardTitle>{title}</CardTitle>
            <CardDesc>{desc}</CardDesc>
        </SkinnyCard>
    </SkinnyCardWrap>
);

export { SkinnyHomeCardComponent, SkinnyCategoryCardComponent };
