import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Heading1, HeadingSubTitle } from '../global/Headings';
import colours from '../global/colours';
import SkinnyPhpKbBreadCrumbs from './SkinnyPhpKbBreadCrumbs';
import SkinnyPhpKbFeaturedArticlesComponent from './SkinnyPhpKbFeaturedArticlesComponent';
import SkinnyPhpKbArticlesByCategoryComponent from './SkinnyPhpKbArticlesByCategoryComponent';
import SkinnyPhpKbSubCategoryListComponent from './SkinnyPhpKbSubCategoryListComponent';
import SkinnyPhpKbSearchComponent from './SkinnyPhpKbSearchComponent';
import { fetchSubCategories, fetchArticles, fetchPopularArticles, fetchFeaturedArticles } from '../../api/PhpKbApi';

const ArticleBlockWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const GreySection = styled.div`
    background: ${colours.lightGrey};
    padding: 40px 0;
`;

const SkinnyPhpKbCategoryComponent = (props) => {
    const [categoryId] = useState(props.match.params.cId);
    const [parentId, setParentId] = useState(props.match.params.parentId);
    const [currentCategory] = useState(props.category.find((item) => item.id === parseInt(props.match.params.cId)));
    const [subCategories, setSubCategories] = useState([]);
    const [subCategoriesError, setSubCategoriesError] = useState(false);
    const [articlesLoading, setArticlesLoading] = useState(true);
    const [articlesError, setArticlesError] = useState(true);
    const [articles, setArticles] = useState([]);
    const [popularArticles, setPopularArticles] = useState([]);
    const [featuredArticles, setFeaturedArticles] = useState([]);

    const handleCategory = (parentId, cName) => {
        setParentId(parentId);
        props.history.push({
            pathname: `/category/${categoryId}/${parentId}/${cName.toLowerCase().replace(/\s/g, '-')}`,
        });
    };

    async function getSubCategories(cid) {
        await fetchSubCategories(cid)
            .then((data) => {
                setSubCategories(data ? data.result : []);
                setSubCategoriesError(false);
            })
            .catch((error) => {
                setSubCategories([]);
                setSubCategoriesError(true);
            });

        return false;
    }

    async function getArticles(cid) {
        if (subCategories.length <= 0) {
            return false;
        }
        setArticlesLoading(true);
        const categoryId = cid > 0 ? cid : subCategories[0].id;
        setParentId(categoryId);
        await fetchArticles(categoryId)
            .then((data) => {
                setArticles(data ? data.result : []);
                setArticlesError(false);
                setArticlesLoading(false);
            })
            .catch((error) => {
                setArticles([]);
                setArticlesError(true);
                setArticlesLoading(false);
            });

        return false;
    }

    async function getPopularArticles() {
        await fetchPopularArticles()
            .then((data) => setPopularArticles(data ? data.result : []))
            .catch((error) => setPopularArticles([]));
    }

    async function getFeaturedArticles() {
        await fetchFeaturedArticles()
            .then((data) => setFeaturedArticles(data ? data.result : []))
            .catch((error) => setFeaturedArticles([]));
    }

    const breadCrumbJson = [
        { label: 'Home', link: '/', arrow: true },
        { label: 'Help', link: `/${props.baseURL}`, arrow: true },
        { label: `${currentCategory ? currentCategory.title : ''}`, link: '', arrow: false },
    ];

    useEffect(() => {
        getSubCategories(props.match.params.cId);
        getFeaturedArticles();
        getPopularArticles();
    }, []);

    useEffect(() => {
        getArticles(parentId > 0 ? parentId : 0);
    }, [subCategories, parentId]);

    return (
        <div>
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <SkinnyPhpKbBreadCrumbs content={breadCrumbJson}></SkinnyPhpKbBreadCrumbs>
                        </div>
                    </div>

                    <SkinnyPhpKbSearchComponent history={props.history} />
                </div>
            </div>
            {subCategoriesError ? <p>Error</p> : ''}
            {subCategories.length > 0 && (
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                {currentCategory && <Heading1>{currentCategory.title}</Heading1>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-3">
                                <SkinnyPhpKbSubCategoryListComponent
                                    subCategories={subCategories}
                                    parentId={parentId}
                                    handleCategory={handleCategory}
                                />
                            </div>
                            <div className="col-xs-12 col-md-9">
                                <ArticleBlockWrapper>
                                    {!articlesLoading ? (
                                        <SkinnyPhpKbArticlesByCategoryComponent
                                            content={articles}
                                            baseURL={props.baseURL}
                                        />
                                    ) : (
                                        <h3>Loading...</h3>
                                    )}
                                    {!articlesLoading && articlesError && (
                                        <div>No articles listed in this category.</div>
                                    )}
                                </ArticleBlockWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <GreySection>
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <HeadingSubTitle>Most searched articles</HeadingSubTitle>
                                <SkinnyPhpKbFeaturedArticlesComponent
                                    baseURL={props.baseURL}
                                    content={popularArticles}
                                />
                            </div>
                            <div className="col-xs-12 col-md-6" id="featured-articles">
                                <HeadingSubTitle>Featured articles</HeadingSubTitle>
                                <SkinnyPhpKbFeaturedArticlesComponent
                                    baseURL={props.baseURL}
                                    content={featuredArticles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </GreySection>
        </div>
    );
};

export default SkinnyPhpKbCategoryComponent;
