const colours = {
    darkGrey: '#545454',
    white: '#fff',
    grey: '#646464',
    darkerGrey: '#797979',
    lightBlack: '#070707',
    lightGrey: '#e8e9e8',
    orange: '#fb6413',
    pink: '#fcb5ca',
};

export default colours;
