import React from 'react';
import styled from 'styled-components';
import HomePromoItem from './HomePromoItem';
import colours from '../global/colours';

const HomePromoWrapper = styled.div`
    display: flex;
    justify-content: space-around;
`;

const HomePromoComponent = props => (
    <HomePromoWrapper>
        {props.content.map((item, key) => (
            <HomePromoItem
                key={key}
                contentalign={item.HomePromoItem.align}
                svg={item.HomePromoItem.svg}
                bgColour={colours.darkGrey}
                content={item.HomePromoItem.text}
                link={item.HomePromoItem.link}
            />
        ))}
    </HomePromoWrapper>
);

export default HomePromoComponent;
