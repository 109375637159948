const fonts = {
    h1: { size: '4rem', lineHeight: '1rem' },
    p: { size: '1rem', lineHeight: '1.3rem' },
    homePagePromoItem: { size: '1.2rem', lineHeight: '1.2rem' },
    accordionTitle: { size: '1.2rem', linHeight: '1.3rem' },
};

const fontSizes = Object.keys(fonts).reduce(
    (sum, font) =>
        Object.assign(sum, {
            [font]: `font-size: ${fonts[font].size}`,
        }),
    {},
);

const fontLineHeight = Object.keys(fonts).reduce(
    (sum, font) =>
        Object.assign(sum, {
            [font]: `line-height: ${fonts[font].lineHeight}`,
        }),
    {},
);

export { fontLineHeight, fontSizes };
