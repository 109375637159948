import React from 'react';
import styled from 'styled-components';
import colours from '../global/colours';
import { ChatIcon, EmailIcon } from '../global/Icon';

const SkinnyPhpKbNeedMoreHelpWrapper = styled.div`
    background: ${colours.pink};
    padding: 20px 0;
    margin-top: 40px;
`;

const NeedMoreHelpLink = styled.a`
    color: ${colours.grey};
    border: 0;
    font-weight: bold;
    position: relative;
    margin-left: 40px;
`;
const IconWrapper = styled.div`
    position: absolute;
    top: -10px;
`;

const SkinnyPhpKbNeedMoreHelp = () => (
    <SkinnyPhpKbNeedMoreHelpWrapper>
        <div className="wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <strong>Need More help?</strong>
                    </div>
                    <div className="col-xs-12 col-md-3">
                        <NeedMoreHelpLink href="/help/">
                            <IconWrapper>
                                <EmailIcon height="38" width="38" color={colours.white} />
                            </IconWrapper>
                            Get in touch
                        </NeedMoreHelpLink>
                    </div>
                    <div className="col-xs-12 col-md-3">
                        <NeedMoreHelpLink className="va-button" href="/help/">
                            <IconWrapper>
                                <ChatIcon height="38" width="38" color={colours.white} />
                            </IconWrapper>
                            Chat with us
                        </NeedMoreHelpLink>
                    </div>
                </div>
            </div>
        </div>
    </SkinnyPhpKbNeedMoreHelpWrapper>
);

export default SkinnyPhpKbNeedMoreHelp;
