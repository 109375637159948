import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Heading1, HeadingSubTitle } from '../global/Headings';
import colours from '../global/colours';
import SkinnyPhpKbSearchComponent from './SkinnyPhpKbSearchComponent';
import SkinnyPhpKbBreadCrumbs from './SkinnyPhpKbBreadCrumbs';
import { searchArticles } from '../../api/PhpKbApi';
import { articleTypes } from '../global/constants';

const SkinnySearchComponentWrapper = styled.div`
    padding-bottom: 200px;
`;
const SearchItem = styled.a`
    width: 100%;
    display: block;
    padding: 40px 0 20px;
    border-bottom: solid 1px ${colours.lightGrey};
    cursor: pointer;
    &:hover {
        h3 {
            color: ${colours.orange};
        }
        border-bottom: solid 1px ${colours.orange};
    }
`;
const SearchItemTitle = styled.h3``;
const SearchItemSummary = styled.div`
    margin: 10px 0;
    color: ${colours.darkGrey};
`;
const SeachItemCategory = styled.div`
    display: flex;
    text-transform: uppercase;
    font-size: 10px;
    color: ${colours.darkGrey};
    flex-wrap: wrap;
    div {
        margin-right: 20px;
    }
`;

const SkinnyPhpKbSearchPageComponent = (props) => {
    const searchValue = props.match.params.searchValue;
    const [articles, setArticles] = useState([]);
    const getArticles = (searchValue) => {
        setArticles([]);
        return searchArticles(searchValue, 100)
            .then((data) => {
                const tmpData = data && data.result ? data.result : [];
                const setNewData = tmpData.filter(function (item) {
                    if (item.categories && item.categories.result && item.categories.result.length > 0) {
                        if (item.categories.result[0].status === articleTypes.PUBLIC) {
                            return item;
                        }
                    }
                });
                setArticles(setNewData);
            })
            .catch((error) => {
                setArticles([]);
            });
    };

    const displayArticle = (id, title) => {
        props.history.push({
            pathname: `/article/${id}/${title.toLowerCase().replace(/\s/g, '-')}`,
        });
    };

    const breadCrumbJson = [
        { label: 'Home', link: '/', arrow: true },
        { label: 'Help', link: `/${props.baseURL}`, arrow: true },
        { label: `${searchValue || ''}`, link: '', arrow: false },
    ];

    useEffect(() => {
        getArticles(searchValue);
    }, [searchValue]);

    return (
        <SkinnySearchComponentWrapper>
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <SkinnyPhpKbBreadCrumbs content={breadCrumbJson}></SkinnyPhpKbBreadCrumbs>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Heading1>Search Results</Heading1>

                            <SkinnyPhpKbSearchComponent history={props.history} defaultValue={searchValue} />

                            {articles &&
                                articles.map((item, index) => (
                                    <SearchItem key={index} onClick={() => displayArticle(item.id, item.title)}>
                                        <SearchItemTitle
                                            dangerouslySetInnerHTML={{ __html: item.title }}
                                        ></SearchItemTitle>
                                        <SeachItemCategory>
                                            {item.categories.total > 0 &&
                                                item.categories.result.map((item1, index1) => (
                                                    <div key={index1}> {item1.name}</div>
                                                ))}
                                        </SeachItemCategory>
                                        <SearchItemSummary
                                            dangerouslySetInnerHTML={{ __html: item.summary }}
                                        ></SearchItemSummary>
                                    </SearchItem>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </SkinnySearchComponentWrapper>
    );
};

export default SkinnyPhpKbSearchPageComponent;
