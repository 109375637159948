import React from 'react';
import styled from 'styled-components';
import media from '../global/media';
import colours from '../global/colours';
import { arrowDown } from '../global/images';

const CategoryListWrapper = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;
const ArrowIcon = styled.img`
    position: absolute;
    right: 0;
    transform: rotate(270deg);
    color: ${colours.lightGrey};
    width: 12px;
    height: 12px;
    top: 12px;
`;

const CategoryList = styled.li`
    margin: 0;
    padding: 10px 0;
    border-bottom: solid 1px ${colours.lightGrey};
    position: relative;
    cursor: pointer;
    display: none;
    &:hover,
    &.active {
        border-bottom: solid 1px ${colours.orange};
        color: ${colours.orange};
        ${ArrowIcon} {
            fill: ${colours.orange};
        }
    }
    ${media.md} {
        display: block;
    }
`;

const SkinnyPhpKbSubCategoryListComponent = ({ subCategories, handleCategory, parentId }) => (
    <CategoryListWrapper>
        {subCategories.map(item => (
            <CategoryList
                onClick={() => handleCategory(item.id, item.name)}
                key={item.id}
                className={parentId === item.id ? 'active' : ''}
            >
                <div dangerouslySetInnerHTML={{ __html: item.name }}></div>
                <ArrowIcon alt="Icon" src={arrowDown} />
            </CategoryList>
        ))}
    </CategoryListWrapper>
);
export default SkinnyPhpKbSubCategoryListComponent;
