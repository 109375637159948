import React from 'react';
import styled from 'styled-components';
import colours from '../global/colours';
import media from '../global/media';
import { articleTypes } from '../global/constants';

const ArticlesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const ArticleBlock = styled.a`
    width: 100%;
    display: block;
    margin: 0 4% 4% 0;
    cursor: pointer;
    color: ${colours.grey};
    padding-bottom: 20px;
    border-bottom: solid 1px ${colours.lightGrey};
    &:hover {
        color: ${colours.orange};
        border-bottom: solid 1px ${colours.orange};
        h4 {
            color: ${colours.orange};
        }
    }
    ${media.md} {
        width: 46%;
    }
`;

const ArticleBlockTitle = styled.h4`
    width: 100%;
    font-weight: bold;
    color: ${colours.grey};
`;

const ArticleBlockContent = styled.div`
    width: 100%;
    color: ${colours.grey};
`;

const SkinnyPhpKbArticlesByCategoryComponent = ({ baseURL, content }) => (
    <ArticlesWrapper>
        {content &&
            content.length > 0 &&
            content.map((item) => {
                return (
                    item.type === articleTypes.PUBLIC && (
                        <ArticleBlock
                            key={item.id}
                            href={`/${baseURL}#/article/${item.id}/${item.title.replace(/\s/g, '-')}`}
                        >
                            <ArticleBlockTitle dangerouslySetInnerHTML={{ __html: item.title }}></ArticleBlockTitle>
                            <ArticleBlockContent
                                dangerouslySetInnerHTML={{ __html: item.content }}
                            ></ArticleBlockContent>
                        </ArticleBlock>
                    )
                );
            })}
    </ArticlesWrapper>
);

export default SkinnyPhpKbArticlesByCategoryComponent;
