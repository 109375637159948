import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Heading1, HeadingSubTitle } from '../global/Headings';
import colours from '../global/colours';
import { articleTypes } from '../global/constants';
import SkinnyPhpKbBreadCrumbs from './SkinnyPhpKbBreadCrumbs';
import { fetchCurrentArticle, searchArticles } from '../../api/PhpKbApi';
import SkinnyPhpKbSearchComponent from './SkinnyPhpKbSearchComponent';
import SkinnyPhpKbNeedMoreHelp from './SkinnyPhpKbNeedMoreHelp';
import SkinnyPhpKbFeaturedArticlesComponent from './SkinnyPhpKbFeaturedArticlesComponent';

const SkinnyArticleComponentWrapper = styled.div`
    padding-bottom: 40px;
    img {
        max-width: 100%;
    }
`;

const RelatedArticlesSection = styled.div`
    background: ${colours.lightGrey};
    padding: 40px 0;
`;

const NotViewableArticle = styled.div`
    padding: 40px 0;
    font-weight: bold;
`;

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const SkinnyPhpKbArticleComponent = (props) => {
    const [articleData, setArticleData] = useState([]);
    const [relatedArticles, setRelatedArticles] = useState([]);
    const { aId, aName } = props.match.params;

    const currentPosRef = useRef(null);
    const executeScroll = () => scrollToRef(currentPosRef);

    const searchLimit = 10;

    const Status = {
        LOADING: 'Loading',
        ERROR: 'Error',
        COMPLETED: 'Completed',
    };

    const [status, setStatus] = useState(Status.LOADING);

    function getCurrentArticle() {
        setStatus(Status.LOADING);
        return fetchCurrentArticle(aId)
            .then((data) => {
                setArticleData(data && data.result ? data.result[0] : []);
                setStatus(Status.COMPLETED);
            })
            .catch((error) => {
                setArticleData([]);
                setStatus(Status.ERROR);
            });
    }

    function getRelatedArticles() {
        return searchArticles(aName.replace(/-/g, ' '), searchLimit)
            .then((data) => {
                setRelatedArticles(data && data.result ? data.result : []);
            })
            .catch((error) => {
                setRelatedArticles([]);
            });
    }

    useEffect(() => {
        getCurrentArticle();
        getRelatedArticles();
        executeScroll();
    }, [aId, aName]);

    const breadCrumbJson = [
        {
            label: 'Home',
            link: '/',
            arrow: true,
        },
        {
            label: 'Help',
            link: `/${props.baseURL}`,
            arrow: true,
        },
        { label: `${articleData.title || ''}`, link: '' },
    ];

    return (
        <SkinnyArticleComponentWrapper ref={currentPosRef}>
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <SkinnyPhpKbBreadCrumbs content={breadCrumbJson}></SkinnyPhpKbBreadCrumbs>
                        </div>
                    </div>

                    <SkinnyPhpKbSearchComponent history={props.history} />
                </div>
            </div>

            <div className="wrapper">
                {status === Status.LOADING ? (
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <h3>Loading...</h3>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                {articleData &&
                                    (articleData.type === articleTypes.PRIVATE ? (
                                        <div>
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <NotViewableArticle>
                                                        This article is protected by admin
                                                    </NotViewableArticle>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <Heading1>
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: articleData.title }}
                                                        ></div>
                                                    </Heading1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: articleData.content }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {status === Status.ERROR && <div className="alert alert-danger">Error...</div>}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <SkinnyPhpKbNeedMoreHelp />
            <RelatedArticlesSection>
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                <HeadingSubTitle>Answer others found helpful</HeadingSubTitle>
                                <SkinnyPhpKbFeaturedArticlesComponent
                                    baseURL={props.baseURL}
                                    content={relatedArticles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </RelatedArticlesSection>
        </SkinnyArticleComponentWrapper>
    );
};

export default SkinnyPhpKbArticleComponent;
