import React from 'react';
import styled from 'styled-components';
import colours from '../global/colours';
import { arrowDown } from '../global/images';

const BreadCrumbWrapper = styled.div`
    padding: 20px 0;
`;
const BreadCrumbUl = styled.ul`
    margin: 0;
    padding: 0;
`;
const BreadCrumbLi = styled.li`
    display: inline;
    position: relative;
    padding-right: 20px;
`;
const ArrowIcon = styled.img`
    transform: rotate(270deg);
    width: 12px;
    position: absolute;
    right: 0px;
    top: 4px;
`;
const BreadCrumbLink = styled.a`
    color: ${colours.grey};
    text-decoration: none;
    border: 0;
    &:visited {
        color: ${colours.grey};
        text-decoration: none;
    }
    &.active {
        font-weight: bold;
    }
    &:hover {
        color: ${colours.grey};
        border-bottom: solid 1px ${colours.grey};
    }
`;
const BreadCrumbNoLink = styled.span`
    color: ${colours.grey};
    text-decoration: none;
    border: 0;
`;

const SkinnyPhpKbBreadCrumbs = ({ content }) => (
    <BreadCrumbWrapper>
        <BreadCrumbUl>
            {content &&
                content.map((item, index) => (
                    <BreadCrumbLi key={index}>
                        {item.link ? (
                            <BreadCrumbLink href={item.link}>
                                {item.label}
                                {item.arrow && <ArrowIcon alt="Icon" src={arrowDown} />}
                            </BreadCrumbLink>
                        ) : (
                            <BreadCrumbNoLink>
                                {item.label}
                                {item.arrow && <ArrowIcon alt="Icon" src={arrowDown} />}
                            </BreadCrumbNoLink>
                        )}
                    </BreadCrumbLi>
                ))}
        </BreadCrumbUl>
    </BreadCrumbWrapper>
);

export default SkinnyPhpKbBreadCrumbs;
