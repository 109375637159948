import styled from 'styled-components';
import colours from './colours';

const Heading1 = styled.h1`
    position: relative;
    padding: 60px 0 30px;
    margin-bottom: 30px;
    &:after {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100px;
        height: 5px;
        background: ${colours.orange};
    }
`;

const Heading2 = styled.h2`
    position: relative;
    padding: 60px 0 30px;
    margin-bottom: 30px;

    &:after {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100px;
        height: 5px;
        background: ${colours.orange};
    }
`;

const HeadingSubTitle = styled.h4`
    position: relative;
    padding: 0 0 30px;
    font-weight: bold;
    color: ${colours.grey};
    &:after {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${colours.grey};
    }
`;

export { Heading1, Heading2, HeadingSubTitle };
