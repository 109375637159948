import React from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import SkinnyPhpKbHomeComponent from './SkinnyPhpKbHomeComponent';
import SkinnyPhpKbCategoryComponent from './SkinnyPhpKbCategoryComponent';
import SkinnyPhpKbArticleComponent from './SkinnyPhpKbArticleComponent';
import SkinnyPhpKbSearchPageComponent from './SkinnyPhpKbSearchPageComponent';

const SkinnyPhpKbWrapper = styled.div``;

const SkinnyPhpKbComponent = ({ baseurl: baseURL, category, heading, description, mainlinks: mainLinks }) => {
    return (
        <SkinnyPhpKbWrapper>
            <Route
                exact
                path={`/`}
                render={(props) => (
                    <SkinnyPhpKbHomeComponent
                        {...props}
                        baseURL={baseURL}
                        category={category}
                        mainLinks={mainLinks}
                        description={description}
                        heading={heading}
                    />
                )}
            />
            <Route
                exact
                path={`/:view`}
                render={(props) => (
                    <SkinnyPhpKbHomeComponent
                        {...props}
                        baseURL={baseURL}
                        category={category}
                        mainLinks={mainLinks}
                        description={description}
                        heading={heading}
                    />
                )}
            />
            <Route
                exact
                path={`/search/:searchValue`}
                render={(props) => <SkinnyPhpKbSearchPageComponent {...props} baseURL={baseURL} category={category} />}
            />
            <Route
                exact
                path={`/category/:cId/:parentId/:cName`}
                render={(props) => <SkinnyPhpKbCategoryComponent {...props} baseURL={baseURL} category={category} />}
            />
            <Route
                exact
                path={`/article/:aId/:aName`}
                render={(props) => <SkinnyPhpKbArticleComponent {...props} baseURL={baseURL} category={category} />}
            />
        </SkinnyPhpKbWrapper>
    );
};

const SkinnyPhpKbComponentWrapper = withRouter(SkinnyPhpKbComponent);

const SkinnyPhpKbApp = (props) => {
    return (
        <Router>
            <SkinnyPhpKbComponentWrapper {...props} />
        </Router>
    );
};

export { SkinnyPhpKbApp, SkinnyPhpKbComponent };
